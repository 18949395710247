import React from 'react';

const PDFViewer = (props) => { 
  return (
    <div>
      <embed type="application/pdf" src="HasoTechnology - Your Technology Partner.pdf" width="100%" height="600px" />
      
    </div>
  );
}

export default PDFViewer;
