import React from 'react'
// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Parallax } from "react-scroll-parallax";
import { AnimatePresence, m } from 'framer-motion';
import { Form, Formik } from "formik";
import * as Yup from "yup";


// Components
import Clients from '../../Components/Clients/Clients'
import Header, { HeaderNav, Menu, SearchBar, Topbar } from '../../Components/Header/Header'
import { fadeIn, fadeInRight, fadeInLeft, zoomIn } from '../../Functions/GlobalAnimations'
import IconWithText from '../../Components/IconWithText/IconWithText'
import Lists from '../../Components/Lists/Lists'

import PricingTable04 from '../../Components/PricingTable/PricingTable04';

import CustomModal from '../../Components/CustomModal'
import Buttons from '../../Components/Button/Buttons'

import Counter from '../../Components/Counters/Counter'
import FooterStyle03 from '../../Components/Footers/FooterStyle03'
import SideButtons from "../../Components/SideButtons";

import TestimonialsCarousel09 from '../../Components/TestimonialCarousel/TestimonialsCarousel09';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import { Input } from '../../Components/Form/Form'
import MessageBox from "../../Components/MessageBox/MessageBox";


// Data
import { CounterData02 } from '../../Components/Counters/CounterData'
import { TextSliderData02 } from '../../Components/TextSlider/TextSliderData'

import TextSlider02 from '../../Components/TextSlider/TextSlider02'

import { blogData } from "../../Components/Blogs/BlogData";
import InViewPort from '../../Components/InViewPort';
import { pricingTable04 } from '../../Components/PricingTable/PricingTableData';
import { TestimonialsCarouselData9 } from '../../Components/TestimonialCarousel/TestimonialsCarouselData';
import { ListData05} from '../../Components/Lists/ListsData'
import { ClientData05 } from "../../Components/Clients/ClientsData";

import Services from '../../Components/Services/Services';
import { Helmet } from 'react-helmet';

export const listDataFaq = [
    {
        title: "Most Popular Topics",
        content: [
            "Do you offer services beyond software development?",
            "What kind of projects do you develop?",
            "Do you provide after support?"
        ]
    },
    {
        title: "Web App Development",
        content: [
            "How much does it cost for a project?",
            "What are the technologies your using?",
            "Can you maintain our website?"
        ]
    },
    {
        title: "Mobile App Development",
        content: [
            "What programming languages are commonly used for Mobile App Development?",
            "What are some common Challenges in Mobile App Development?",
            "How do I Choose the Right Mobile App Development Company in Canada?"
        ]
    },
    {
        title: "Angular App Development",
        content: [
            "What are the advantages of outsourcing AngularJS development?",
            "Can an AngularJS development company customize solutions according to my business needs?",
            "Can I use Angular for mobile app development?"
        ]
    },
    {
        title: "CRM App Development",
        content: [
            "What is Custom CRM Software Development?",
            "How long does it take to develop a CRM system?",
            "Are there hidden costs in CRM development?"
        ]
    },
    {
        title: "Desktop App Development",
        content: [
            "Will I ever have to pay any fees like renewals?",
            "Skrill has been reinstated as a payment option",
            "Guidelines for item comments and ratings"
        ]
    }
]




const serviceData = [
    {
      title:"Client Focus",
      img: "/assets/img/webp/how-haso-differs-1.webp",
      content: "Our clients are our top priority, guiding every decision we make. Their needs and satisfaction drive our commitment to excellence.",
    },
    {
      title:"Simplified Accessibility",  
      img: "/assets/img/webp/how-haso-differs-3.webp",
      content: "At every phase of software development, we continuously seek innovative ways to improve user experiences, making goal achievement effortless.",
    },
    {
      title:"Understanding Project Needs",   
      img: "/assets/img/webp/how-haso-differs-2.webp",
      content: "We focus on understanding the root issue thoroughly and asking the most relevant questions to address it efficiently.",
    }
  ]

const listData = [
    {
        icon: "fas fa-check",
        content: "Gain a competitive edge with data-driven insights that fuel informed decision-making.",
    },
    {
        icon: "fas fa-check",
        content: "Experience the power of innovation with our ever-evolving software that adapts to the changing business landscape.",
    },
    {
        icon: "fas fa-check",
        content: "Empower your team with intuitive tools that drive productivity and collaboration.",
    },
]

const IconWithTextData = [
    {
        icon: "line-icon-Favorite-Window text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Freedom from Subscription",
        content: "Escape the never-ending cycle of payments and regain control over your financial commitments",
    },
    {
        icon: "line-icon-Talk-Man text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Business Empowerment",
        content: "Our bespoke apps are designed to empower your business and enhance its capabilities",
    },
    {
        icon: "line-icon-Gear-2 text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Customization",
        content: "Tailored solutions ensure that the software aligns perfectly with your unique requirements",
    },
    {
        icon: "line-icon-Sound text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Tailored Solutions",
        content: "Enjoy a personalized approach to technology solutions that cater to your unique challenges and goals",
    },
    {
        icon: "line-icon-Download-fromCloud text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Cost Control",
        content: "Regain control over your budget with applications that match and surpass popular SaaS offerings",
    },
    {
        icon: "line-icon-Archery-2 text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] text-[40px]",
        title: "Competitive Advantage",
        content: "Gain a competitive edge with applications designed to meet and exceed industry standards",
    },
]


// Filter the blog data category wise
const blogCleanData = blogData.filter((item) => item.blogType === "clean").filter(item => item.category.includes("consulting"));
console.log(blogCleanData);
const ConsultingPage = (props) => {
    // const imageStyle = {
    //     opacity: 1,
    //     transform: 'translateX(10px) translateY(-20px) translateZ(0px)'
    //   };
 
    const isMobile = window.innerWidth <= 600;
    return (
        <>
        <div style={props.style}>
            <Helmet>
            <title>HasoTechnology - Top Web and Mobile App Development Company in Toronto</title>
            <meta name="description"
    content="We are a leading web and mobile development company in Toronto committed to developing empirical solutions that enable businesses to thrive in the digital marketplace."/>
    <meta name="keywords" content="technology"/>
    <link rel="canonical" href="https://hasotech.com/" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="HasoTechnology" />
    <meta property="business:contact_data:street_address" content="24 Jordensen drive, Brampton, ON L6X 0T2, Canada" />
    <meta property="business:contact_data:locality" content="Piscataway" />
    <meta property="business:contact_data:region" content="New Jersey" />
    <meta property="business:contact_data:postal_code" content="ON L6X 0T2" />
    <meta property="business:contact_data:country_name" content="Canada" />
            </Helmet>
            <SideButtons />
            {/* Header Start */}
            <Header topSpace={{ md: true }} type="reverse-scroll border-b border-[#ffffff1a]">
                <Topbar className="header-with-topbar bg-hasocolor border-bottom pl-[35px] pr-[50px] sm:hidden lg:pr-[50px] md:pl-[15px] md:pr-[30px]">
                    <Container fluid>
                        <Row className="flex items-center justify-between">
                            <Col sm="auto">
                            
                                <Buttons ariaLabel="button" target="_blank" href="https://calendly.com/dilipwk/the-next-step" className="btn-fill mx-[10px] bg-[#063f74] hover:bg-[#fff] text-[#fff] hover:text-[#063f74] font-medium font-serif border-none uppercase" size="xl" title="TALK TO AN EXPERT" />

                            </Col>
                            <Col sm="auto" className="!pr-0">
                                <div className="flex text-sm leading-[30px]">
                                    <span className="flex items-center  py-[9px] px-[18px]">
                                        <a href="https://wa.me/917010662317" className="flex items-center text-white">
                                        <i className="fab fa-whatsapp text-[20px] mr-[10px] text-white"></i>+91-70106 62317                                       
                                        </a>
                                    </span>

                                    <span className="flex items-center  py-[9px] px-[18px]">
                                        <a href="tel:+917010662317" className="flex items-center text-white">
                                            <i className="fas fa-phone-alt mr-[10px] text-md text-white"></i> +91-70106 62317
                                        </a>
                                    </span>

                                    <span className="flex items-center  py-[9px] pl-[18px]">
                                        <a href="mailto:info@hasotech.com" target="_blank" className="flex items-center text-white" rel="noreferrer">
                                            <i className="far fa-envelope mr-[10px] text-md text-white"></i> info@hasotech.com
                                        </a>
                                    </span>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Topbar>
                <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-0" containerClass="sm:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.webp' data-rjs='/assets/img/logo-mob.webp' alt='logo' />
                                <img className="alt-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.webp' data-rjs='/assets/img/logo-mob.webp' alt='logo' />
                                <img className="mobile-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.webp' data-rjs='/assets/img/logo-mob.webp' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block" style={{ paddingTop: '0px' }}>
                        <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                        <SearchBar className="pr-0" />

                    </Col>
                </HeaderNav>                
            </Header>
            {/* Header End */}

            {/* Section Start */}
            <section id="about" className="py-[80px] lg:py-[60px] md:py-[45px] sm:py-[20px] xs:py-[12px]">
                <Container>
                    <Row className="items-center md:justify-center">
                    <Col xs={12} lg={5} md={9} className="lg:text-start md:mb-18 md:mx-auto md:text-center">
  <span
    className="inline-block mb-[20px] font-medium text-base font-serif uppercase text-start text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] md:block" loading="lazy"
    style={{ paddingTop: '0px' , marginTop: '60px' }}
  >
    YOUR TECHNOLOGY PARTNER
  </span>

  <h4 className="mb-25px font-semibold text-darkgray font-serif mx-0 w-100 text-start lg:mb-15px" loading="lazy">
    Resolving business challenges through strategic Intellect
    <br />
    <span className="text-gradient bg-gradient-to-r from-[#063f74] to-[#063f74] font-semibold" loading="lazy">
      Solutions!
    </span>
  </h4>

  <p className="w-[80%] lg:w-[95%] mb-[25px] sm:mb-[15px] text-start" loading="lazy">
    We employ the latest tech trends to craft intellect solutions, addressing business challenges and keeping you ahead in the industry.
  </p>

  <div className="text-start">
    <Buttons
      ariaLabel="Discover Services"
      href="/our-services"
      className="btn-fill mx-[10px] font-medium font-serif rounded-none uppercase"
      themeColor="#063f74"
      color="#fff"
      size="lg"
      title="Discover Services"
      loading="lazy"
    />
  </div>
</Col>

                        <Col lg={7} md={9} xs={12} className="px-[55px] md:py-0 md:px-0 md:pl-[5px] sm:pr-[50px]"><br/><br/>
                        <m.figure className="image-back-offset-shadow w-100" style={{ display: isMobile ? 'none' : 'block' }} loading="lazy">
                        <img 
  rel="preload"
  className="rounded-[6px]"
//   srcSet="/assets/img/webp/dashboardimg-300w.webp 300w,
//           /assets/img/webp/dashboardimg-600w.webp 600w, /assets/img/webp/dashboardimg-800w.webp 800w"
//   sizes="(max-width: 800px) 6oopx 300px"
  src="/assets/img/webp/project (1).webp"
  alt="Description of the image"
  data-no-retina="" 
//   width={600} height={500}
style={{ 
    opacity: 1, 
    transform: 'translate(10px, -20px)', 
    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.3)' 
  }}
/>
</m.figure>

                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            <m.section
                className="py-[50px] lg:py-[60px] md:py-[47px] sm:py-[40px] xs:py-[25px] overflow-hidden bg-lightgray"
                {...fadeIn}
            >
                <Container>
                    <Row className="justify-center">
                        <Col md={10} lg={7} className="text-center">
                            <span className="font-serif font-medium text-[#063f74] text-xmd uppercase mb-[20px] inline-block sm:mb-[10px]" loading="lazy">
                               HAVE A BILLION DOLLAR APP IDEA?
                            </span>
                            <h4 className="font-serif font-semibold text-darkgray inline-block text-center sm:mb-[33px]" loading="lazy">
                            Connect Now Or Schedule Consultation Later For <span className="text-gradient bg-gradient-to-tr from-[#063f74] to-[#063f74] font-semibold" loading="lazy">Free!</span>
                            </h4>
                        </Col>
                   
                        <Col md={9}>
                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                                onSubmit={async (values, actions) => {
                                    actions.setSubmitting(true)
                                    const response = await sendEmail(values)
                                    response.status === "success" && resetForm(actions)
                                    window.alert("Email Sent Successfully!")
                                }}
                            >
                                {({ isSubmitting, status }) => (
                                    <div className="relative subscribe-style-05 mt-[20px] mb-[30px]">
                                        <Form className="relative w-[95%] rounded-md">
                                            <Input showErrorMsg={false} type="email" name="email" className="overflow-hidden rounded-md border-[1px] large-input border-solid border-transparent shadow-[0_0_30px_rgba(0,0,0,0.08)]" placeholder="Enter your email address" />
                                            <button aria-label="subscribe button" type="submit" className={`text-xs tracking-[1px] py-[12px] px-[28px] -bottom-[2px] !border-0 uppercase xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-[#063f74] text-lg leading-none xs:mr-0"></i></button>
                                        </Form>
                                        <AnimatePresence>
                                            {status && <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="absolute !z-[1] mt-[25px]"><MessageBox className="rounded-[4px] text-md py-[10px] px-[20px] w-[95%]" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" /></m.div>}
                                        </AnimatePresence>
                                    </div>
                                )}
                            </Formik>
                            <center>
                                <Buttons ariaLabel="SCHEDULE A CALL" to="https://calendly.com/dilipwk/the-next-step" className="mr-[38px] font-medium font-serif uppercase md:mb-[15px]" themeColor={["#063f74", "#063f74"]} size="xl" color="#fff" title="SCHEDULE A CALL" target="_blank" />
                                {/* Modal Component Start */}
                                <CustomModal.Wrapper className="inline-block"
                                    modalBtn={<Buttons ariaLabel="Discover us" type="submit" className="font-medium font-serif xs:!p-0 uppercase btn-link after:h-[2px] text-hasocolor md:text-md md:mb-[15px] after:bg-[#063f74] hover:text-darkgray" color="#063f74" size="xl" title="Discover us" />} >
                                    <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                        <div className="fit-video">
                                            <iframe
                                                loading="lazy"
                                                width="100%"
                                                height="100%"
                                                class="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                                                src="https://www.youtube.com/embed/ibyyLUzZC9s?autoplay=1"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe>
                                        </div>
                                    </div>
                                </CustomModal.Wrapper>
                            </center>
                        </Col>
                    </Row>
                </Container>
            </m.section>

                       
            {/* About section Start */}
            <section className="py-[50px] overflow-hidden cover-background lg:py-[60px] md:py-[47px] sm:py-[40px] xs:py-[25px]" style={{ backgroundColor: "#ffffff" }}>
                <Container>
                    <Row className="items-center justify-center">
                        <m.div className="col-lg-6 col-md-10 col-xs-10 md:mb-10 consulting-about" {...fadeIn}>
                            <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-8 w-[80%] lg:w-[90%] md:mb-6 xs:mb-8 xs:w-full">We provide solutions to <span className="text-[#063f74] font-semibold">grow your business</span></h2>
                            <p className="w-[70%] mb-[20px] lg:w-[90%] md:mb-[20px] xs:mb-[12px]">Empower your business with intellect tech solutions. Let us turn challenges into triumphs together.</p>
                            <Lists theme="list-style-02" color="#063f74" data={listData} />
                            <div className="mt-[50px] md:mt-[25px] xs:mt-[35px] landscape:md:mt-[40px]">
                                <Buttons ariaLabel="About Company" href="/about-us" className="btn-fill mx-[10px] font-medium font-serif rounded-none uppercase" themeColor="#063f74" color="#fff" size="lg" title="About Company" />
                                <Buttons to="/our-services" className="font-medium pt-0 font-serif text-hasocolor uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-blue xs:mb-0" size="xl" color="#063f74" title="Discover tour" />
                            </div>
                        </m.div>
                        <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
                            <div className="relative">
                                <div className="absolute h-full w-full top-0 left-0 opacity-20"></div>
                                <img className="w-full" alt="" src="/assets/img/webp/hasohome.webp" loading="lazy" width="400px" height="507.66px" />
                            </div>
                        </m.div>
                    </Row>
                </Container>
            </section>
            {/* About section End */}

           {/* Section Start */}
       <section className="py-[60px] lg:py-[47px] md:py-[35px] sm:py-[25px]py-[60px] sm:py-[25px] bg-lightgray">
        <Container>
          <Row className="justify-center">
            <Col xs={12} md={6} className="col-12 text-center mb-[2.5rem]">
              <h4 className="font-serif text-hasocolor font-medium -tracking-[1px] mb-[5px]">How HasoTechnology differs from others</h4>
            </Col>
          </Row>
          <Services grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 sm:gap-y-[15px] " theme='service-style-02' loading="lazy" className="" data={serviceData} animation={fadeIn} />
        </Container>
      </section> 
      {/* Section End */}

            {/* Section Start */}
            <m.section className="py-[60px] border-t overflow-hidden lg:py-[60px] md:py-[47px] sm:py-[40px] xs:py-[25px]" {...fadeIn}>
                <Container fluid className="px-0">
                    <Row className="justify-center">
                        <Col xs={12} md={12} className="text-center mb-[2%]">
                            <Row className="row-cols-1 justify-center">
                                <Col xl={8} lg={10} className="text-center">
                                    <h2 className="heading-4 font-serif font-medium text-darkgray -tracking-[1px]">Top Rated Software Development Company Providing <span className="text-gradient bg-gradient-to-tr from-[#063f74] to-[#063f74] font-semibold">intellect</span> Solutions</h2>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <TextSlider02
                        data={TextSliderData02}
                        carousalOption={{
                            slidesPerView: 1,
                            spaceBetween: 60,
                            loop: true,
                            centeredSlides: true,
                            autoplay: { delay: 4500, disableOnInteraction: false },
                            breakpoints: { 991: { slidesPerView: 2 }, 767: { slidesPerView: 1 } }
                        }}
                        loading="lazy"
                        animation={fadeIn}
                    />
                </Container>
            </m.section>
            {/* Section End */}
             

            {/* Section Start */}
            <m.section className="bg-[#F7F7F7] pt-[60px] lg:pt-[45px] md:pt-[35px] xs:pt-[25px] overflow-hidden" {...fadeIn}>
                <Container>
                    <Row className="items-center">
                        <Col xl={5} lg={6} xs={12} className="pb-12 lg:pb-28 sm:pb-[50px]">
                            <div className="text-xmd mb-[20px] font-serif">Explore Intellect Web Solutions</div>
                            <h5 className="font-serif text-darkgray font-medium mb-[2.5rem]">Passionate Developers at Your Service!</h5>
                            <Row>
                                <Col>
                                    <div className="flex">
                                        <div className="mr-[35px]">
                                            <i className="line-icon-Idea-5 text-[50px] text-hasocolor"></i>
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-hasocolor block mb-[10px] font-serif font-medium">Young Innovators at Work</span>
                                            <p className="w-[95%]">
                                                Meet our team of vibrant young developers, where passion meets innovation. We're a group of tech enthusiasts dedicated to crafting cutting-edge solutions and pushing the boundaries of web and app development. Join us on our journey to redefine digital experiences!
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-12"><div className="h-[1px] bg-[#ededed] my-[20px] w-full xs:my-[30px]"></div></Col>
                                <Col>
                                    <div className="flex">
                                        <div className="mr-[35px]">
                                            <i className="line-icon-Medal-2 text-[50px] text-hasocolor"></i>
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-hasocolor block mb-[10px] font-serif font-medium">Strategic Solutions for Your Business</span>
                                            <p className="w-[95%]">
                                                Unlock tailored web and app solutions designed to address your business challenges. Our team of dedicated young developers is committed to creating intellect strategies that propel your business forward. Experience the power of technology solving real-world problems.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6} xl={{ offset: 1 }} className="align-self-end text-center text-xl-start">
                            <img className="md:mx-auto" loading="lazy" src="/assets/img/webp/lapguy.webp" width="504" height="666" alt="web-developers" />
                        </Col>
                    </Row>

                </Container>
            </m.section>
            {/* Section End */}

            {/* Section Start */}
            <section className="py-[60px] overflow-hidden lg:py-[45px] md:py-[35px] sm:py-[25px]">
                <Container>
                    <m.div className="row justify-center" {...fadeIn}>
                        <Col lg={5} xs={12} sm={6} xl={4} className="text-center mb-8">
                            <span className="font-serif font-medium text-md text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] tracking-[.5px] uppercase inline-block mb-[20px] sm:mb-[10px]">Amazing features</span>
                            <h2 className="heading-4 font-serif font-medium text-darkgray -tracking-[1px]">Custom Apps <span className="text-gradient bg-gradient-to-tr from-[#063f74] to-[#063f74] font-semibold">No Strings Attached!</span></h2>
                        </Col>
                    </m.div>
                    <Row className="items-center justify-between">
                        <Col xs={12} lg={3} md={4} className="text-center md:text-end sm:mb-[30px]">
                            <IconWithText grid="row-cols-1 row-cols-sm-2 row-cols-md-1 gap-y-[2.5rem] text-right sm:justify-center sm:text-center" theme="icon-with-text-03" data={IconWithTextData.slice(0, 3)} className="application-iconwithtext" animation={fadeInRight} animationDelay={0.2} />
                        </Col>
                        <Col xs={12} md={4} className="px-lg-0 sm:mb-[20px]">
                            <m.img width={390} height={726} alt="mobile" loading="lazy" src="/assets/img/webp/mobile_home.webp" className="w-full" {...{ ...zoomIn, transition: { duration: 0.7 } }} />
                        </Col>
                        <Col xs={12} lg={3} md={4}>
                            <IconWithText grid="row-cols-1 row-cols-sm-2 row-cols-md-1 gap-y-[2.5rem] text-left sm:justify-center sm:text-center" theme="icon-with-text-03" data={IconWithTextData.slice(3, 6)} className="application-iconwithtext" animation={fadeInLeft} animationDelay={0.2} />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            {/* Section Start */}
            <div className="py-[60px] lg:py-[60px] md:py-[47px] sm:py-[40px] xs:py-[25px] md:flex md:items-center overflow-hidden relative">
                <Parallax className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] xs:bg-[center_center]" translateY={[-40, 40]} style={{ backgroundColor: "#063f74" }}></Parallax>
                <div className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#063f74] to-[#063f74]"></div>
                <Container className="relative">
                    <Row className="justify-center">
                        <Col xl={7} lg={8} sm={10} xs={12} className="text-center text-white font-serif">
                            <m.div {...fadeIn}>
                                <h2 className="heading-4 font-semibold mb-[22px] sm:mb-[12px]">Ignite Your Web Presence with Intellect Ideas and Insights</h2>
                                <span className="uppercase tracking-[2px]">Unleash Unlimited Power and Customization for Your Digital Success</span>

                            </m.div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Section End */}

            <section className="py-20 overflow-hidden">
                <Container>
                    <Row>
                        <Col className="relative">
                            <center><h2 className="heading-4 font-serif font-medium text-darkgray -tracking-[1px]">3rd Party <span className="text-gradient bg-gradient-to-tr from-[#063f74] to-[#063f74] font-semibold">API Integrations!</span></h2></center><br />
                            <Clients
                                theme="client-logo-style-03"
                                className="swiper-navigation-04 swiper-navigation-light"
                                data={ClientData05}
                                animation={fadeIn}
                                loading="lazy"
                                carousel={true}
                                carouselOption={{
                                    slidesPerView: 1,
                                    loop: true,
                                    spaceBetween: 20,
                                    autoplay: { delay: 3000, disableOnInteraction: false },
                                    navigation: true,
                                    breakpoints: { 1200: { slidesPerView: 4 }, 992: { slidesPerView: 3 }, 768: { slidesPerView: 3 } }
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>


            {/* Section Start */}
            <m.section className="pricing-table-style-04 py-[50px] lg:py-[45px] sm:py-[35px] xs:py-[25px] bg-lightgray" {...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col xs={12} md={6} className="font-serif text-center mb-10 sm:mb-12">
                            <span className="mb-[10px] inline-block uppercase font-medium text-gradient bg-gradient-to-r from-[#063f74] via-[#063f74] to-[#063f74] tracking-[1px]">Simple Pricing</span>
                            <h2 className="heading-4 font-serif font-medium text-darkgray -tracking-[1px]">No Development Cost! <span className="text-gradient bg-gradient-to-tr from-[#063f74] to-[#063f74] font-semibold">Pay As You go!</span></h2>
                        </Col>
                    </Row>
                    <PricingTable04 grid="row-cols-1 row-cols-lg-3 gap-y-[30px] justify-center items-center" theme="pricing-table-style-04" className="col-12 col-lg-4 col-md-7" data={pricingTable04} />
                </Container>
            </m.section>
            {/* Section End */}

            <m.section className="py-[50px] lg:py-[60px] md:py-[47px] sm:py-[40px] xs:py-[25px]" {...fadeIn}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className="text-darkgray font-light">Services which can be provided in a <span className="inline-block font-semibold border-b-2 border-hasocolor">Week</span></h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} className="px-[15px] mb-[15px]">
                            <div className="p-[20px] sm:p-[30px]">
                                <img className="w-full" alt="" loading="lazy" src="/assets/img/webp/week.webp" width="500px" height="607.66px" />
                            </div>
                        </Col>
                        <Col lg={8} md={6} className="px-[30px] mb-[15px] d-flex align-items-center">
                            <div className="p-[20px] sm:p-[30px]">
                                <Lists theme="list-style-05" data={ListData05} animation={fadeIn} />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </m.section>

                {/* Section Start */}
                <m.section className="bg-lightgray py-[60px] lg:py-[47px] md:py-[35px] sm:py-[25px]" {...fadeIn}>
                    <Container>
                        <Row className="justify-center">
                            <Col xl={6} lg={7} md={8} xs={12} className="relative move-black">
                                <TestimonialsCarousel09
                                    data={TestimonialsCarouselData9}
                                    className="testimonial-carousel-style-09"
                                    loading="lazy"
                                    carouselOption={{
                                        slidesPerView: 1,
                                        loop: true,
                                        navigation: true,
                                        autoplay: { delay: 4500, disableOnInteraction: false },
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </m.section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[60px] overflow-hidden lg:py-[47px] bg-[#063f74]">
                    <Container>
                        <Counter
                            theme="counter-style-02"
                            grid="row row-cols-1 row-cols-md-4 row-cols-sm-2 items-center justify-center text-center gap-y-10"
                            className="heading-4 text-white font-serif"
                            as="h2"
                            data={CounterData02}
                            postfix_sign="+"
                            duration={2}
                        />
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[40px] lg:py-[47px] md:py-[35px] sm:py-[25px]">
                    <Container fluid className="px-[12%] xl:px-[10%] lg:px-[3%]">
                        <Row>
                            <Col className="col-12 text-center mb-28">
                                <span className="block font-serif mb-[5px]">Most common questions</span>
                                <h2 className="font-serif text-darkgray font-semibold mb-0">Frequently asked questions</h2>
                            </Col>
                        </Row>
                        <Row className="gap-y-[25px]">
                            {listDataFaq.map((item, i) => {
                                return (
                                    <m.div className="col-12 col-lg-4 col-sm-6" key={i} {...fadeIn}>
                                        <div className="faqs-page-list bg-white rounded box-shadow-small p-16 lg:p-12 h-full">
                                            <span className="font-serif text-xmd text-darkgray font-medium block mb-[15px]">{item.title}</span>
                                            <ul className="p-0 list-style text-start mb-[15px]">
                                                {item.content && item.content.map((item, i) => {
                                                    return (
                                                        <li key={i}><Link to="/faq" className="hover:text-hasocolor">{item}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                            <Buttons ariaLabel="Agency info" href="/faq" className="font-medium font-serif uppercase btn-link !tracking-[.5px] after:h-[2px] after:bg-royalblue hover:text-royalblue" size="md" color="#063f74" title="See all articles" />
                                        </div>
                                    </m.div>
                                )
                            })}
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[60px] lg:py-[45px] md:py-[35px] sm:py-[25px] bg-lightgray">
                    <Container>
                        <Row className="row-cols-1 row-cols-lg-3 row-cols-sm-1 md:gap-y-[50px] sm:gap-y-[30px] text-center">
                            <Col>
                                <i className="line-icon-Geo2-Love text-gradient bg-hasocolor text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">HasoTechnology</div>
                                <p className="w-[75%] lg:w-[90%] md:w-[60%] sm:w-[75%] xs:w-full mx-auto">161/1-1, Kamarajar Salai,
                                    Madurai-625009,
                                    Tamil Nadu, India.</p>
                            </Col>
                            <Col>
                                <i className="line-icon-Headset text-gradient bg-hasocolor text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">Let's Talk</div>
                                <p className="w-[70%] lg:w-full mx-auto">Phone: +9170106 62317</p>
                            </Col>
                            <Col>
                                <i className="line-icon-Mail-Read text-gradient bg-hasocolor text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">E-MAIL US</div>
                                <p className="w-[70%] lg:w-full mx-auto">
                                    <a aria-label="mail" href="mailto:info@hasotech.com" className="hover:text-basecolor">info@hasotech.com</a><br />
                                    <a aria-label="mail" href="mailto:hr@hasotech.com" className="hover:text-basecolor">hr@hasotech.com</a>
                                </p>
                            </Col>                            
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Footer Start */}
                <FooterStyle03 theme="dark" className="text-[#828282] bg-[#0b0b26] consulting-footer" />
                {/* Footer End */}
        </div >
        {/* <div class="h-card">
        <h1 class="p-name">HasoTechnology</h1>
        <p class="p-description">We are a leading web and mobile development company in Toronto committed to developing empirical solutions that enable businesses to thrive in the digital marketplace.</p>
        <div class="h-adr">
          <p class="p-street-address">24 Jordensen drive, Brampton, ON L6X 0T2, Canada</p>
          <p><span class="p-locality">Piscataway</span>, <span class="p-region">New Jersey</span>, <span class="p-postal-code">ON L6X 0T2</span>, <span class="p-country-name">Canada</span></p>
        </div>
      </div> */}
      </>
    )
}

export default ConsultingPage