import React, { useRef } from 'react'
import { Helmet } from 'react-helmet';
// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { m } from "framer-motion";
import { Link, useParams, Navigate } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'


// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import Lists from '../../Components/Lists/Lists'
import Buttons from '../../Components/Button/Buttons'
import Services from '../../Components/Services/Services';
import { fadeIn } from '../../Functions/GlobalAnimations';
import SideButtons from "../../Components/SideButtons";
import FooterStyle03 from '../../Components/Footers/FooterStyle03'
import IconWithText from '../../Components/IconWithText/IconWithText'
import TextBox from '../../Components/TextBox/TextBox';

// Data
import { listDataFaq } from '../Home/Consulting';
import { pageData1 } from './PageData';
import { Swiper,SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard } from 'swiper';



const PageTemplate = (props) => {
    const swiperRef1Fivereasons = useRef(null)
    const { url } = useParams();

    // Find the data object that matches the URL parameter
    const selectedData = pageData1[url];


 // If no matching data is found, you can handle it accordingly
 if (!selectedData) {
  return <Navigate to="/page/error-404" />;
}

    // const swiperRef = React.useRef(null)
    return (
      <>
        <div style={props.style}>
          
          <Helmet>
  <title>{selectedData.metaTitle}</title>
  <meta name="description" content={selectedData.metaDescription ?? ''} />
  {selectedData.metaKeywords && selectedData.metaKeywords.length > 0 && (
    <meta name="keywords" content={selectedData.metaKeywords.join(', ')} />
  )}
  {selectedData.canonicalUrl && (
    <link rel="canonical" href={selectedData.canonicalUrl} />
  )}
  {/* Open Graph tags */}
  <meta property="og:locale" content={selectedData.openGraph?.locale ?? ''} />
  <meta property="og:type" content={selectedData.openGraph?.type ?? ''} />
  <meta property="og:site_name" content={selectedData.openGraph?.siteName ?? ''} />
  <meta property="og:title" content={selectedData.openGraph?.title ?? ''} />
  <meta property="og:description" content={selectedData.openGraph?.description ?? ''} />
  <meta property="og:url" content={selectedData.openGraph?.url ?? ''} />
  <meta property="og:image" content={selectedData.openGraph?.image?.url ?? ''} />
  <meta property="og:image:width" content={selectedData.openGraph?.image?.width ?? ''} />
  <meta property="og:image:height" content={selectedData.openGraph?.image?.height ?? ''} />
  <meta property="og:image:type" content={selectedData.openGraph?.image?.type ?? ''} />
  {/* Conditionally render og:image:alt */}
  <meta property="og:image:alt" content={selectedData.openGraph?.image?.alt ?? ''} />
  {/* Business Contact Data */}
  <meta property="business:contact_data:street_address" content={selectedData.businessContactData?.streetAddress ?? ''} />
  <meta property="business:contact_data:locality" content={selectedData.businessContactData?.locality ?? ''} />
  <meta property="business:contact_data:region" content={selectedData.businessContactData?.region ?? ''} />
  <meta property="business:contact_data:postal_code" content={selectedData.businessContactData?.postalCode ?? ''} />
  <meta property="business:contact_data:country_name" content={selectedData.businessContactData?.countryName ?? ''} />
</Helmet>



            {/* Header Start */}
            <Header topSpace={{ desktop: true }} type="reverse-scroll">
                <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-0" containerClass="sm:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="alt-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="mobile-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block">
                        <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                        <SearchBar className="pr-0" />
                        <HeaderLanguage />
                        <HeaderCart />
                    </Col>
                </HeaderNav>
            </Header>
            {/* Header End */}
            <SideButtons />

            {/* Parallax Section Start */}
            <div className="py-[80px] h-auto overflow-hidden md:relative md:py-[40px]">
                <Parallax className="lg-no-parallax absolute top-[0] w-full h-full lg:bg-cover" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/webp/litho-landing-page-bg-img-01.webp)` }}></Parallax>
                <Container className="h-full relative">
                    <Row className="justify-center h-[300px] sm:h-[250px]">
                        <Col xl={12} lg={6} sm={8} className="text-center flex justify-center flex-col font-serif">
                            <h1 className="text-gradient bg-[#ffffff] mb-[15px] inline-block text-xmd leading-[20px]">{selectedData.subTitle}</h1>
                            <h1 className="text-white font-medium -tracking-[1px] mb-0">{selectedData.title}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Parallax Section End */}

            {/* About section Start */}
            <section className="py-[80px] overflow-hidden cover-background lg:py-[100px] md:py-[95px] sm:py-[80px] xs:py-[50px]" style={{ backgroundColor: "#ffffff" }}>
                <Container>
                    <Row className="items-center justify-center">
                        <m.div className="col-lg-6 col-md-10 md:mb-20 consulting-about" {...fadeIn}>
                            <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">{selectedData.heading} <span className="text-[#063f74] font-semibold">{selectedData.headingName}</span></h2>
                            <p className="w-[70%] mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]">{selectedData.aboutContent}</p>
                            <Lists theme="list-style-02" data={selectedData.listData} />
                            <div className="mt-[50px] md:mt-[25px] xs:mt-[35px] landscape:md:mt-[40px]">
                                <Buttons  ariaLabel="Contact us" href="/contact-us" className="mr-[35px] btn-fill btn-fancy rounded-none font-medium font-serif uppercase md:mb-[15px] xs:mb-0 " themeColor="#063f74" color="#fff" size="sm" title="Contact us" />
                               
                                <Buttons to="/our-services" className="font-medium pt-0 font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-hasocolor xs:mb-0" size="xl" color="#063f74" title="Discover Other Services" />
                            </div>
                        </m.div>
                        <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
                            <div className="relative">
                                <div className="absolute h-full w-full top-0 left-0 opacity-20"></div>
                                <img className="w-full" alt="" src={selectedData.imgBody} width="500px" height="607.66px" />

                            </div>
                        </m.div>
                    </Row>
                </Container>
            </section>
            {/* About section End */}

           
                  {/* Service Section Start */}
                  {selectedData.slide1title && ( <section className="py-0 overflow-hidden">
        <Container fluid>
          <Row>
            <m.div className="col-xs-12 col-xl-5 col-lg-5 cover-background pl-40 h-[650px] flex flex-col justify-center xl:pl-[15px] lg:h-[550px] md:h-[350px] xs:h-[300px]" style={{ backgroundColor:"whitesmoke" }} {...fadeIn}>
              <Row className="justify-center">
                <Col xl={5} sm={9} className="xs:text-center">
                  <span className="heading-6 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">{selectedData.slideLeft1}<br/>{selectedData.slideLeft2}<br/>{selectedData.slideLeft3}<br/>{selectedData.slideLeft4}<br/>{selectedData.slideLeft5}<br/>{selectedData.slideLeft6}</span><br/><br/>
                  <h2 className="heading-5 font-serif font-medium text-white mb-0 xs:w-[70%] mx-auto"><span className="text-[#063f74] font-semibold">{selectedData.slideLeftHeading}</span></h2>
                </Col>
              </Row>
            </m.div>

            <m.div className="col-xl-7 col-lg-7 px-0 relative" {...{ ...fadeIn, transition: { delay: 0.6 } }}>
              <Swiper
                className="swiper-pagination-light h-full relative heroslider lg:h-[590px] sm:h-[590px]"
                slidesPerView={1}
                loop={true}
                ref={swiperRef1Fivereasons}
                modules={[Autoplay, Keyboard]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                keyboard={{ enabled: true, onlyInViewport: true }} >
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}
>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 pr-20 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[75%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide1title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide1context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[75%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide2title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide2context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide3title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide3context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide4title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide4context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide5title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide5context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide6title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide6context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide7title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide7context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                
                <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
                  <div className="flex flex-col justify-end h-full" >
                    <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
                      <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]"><span className="text-[#063f74] font-semibold">{selectedData.slide8title}</span></h2>
                      <p className="w-[89%] sm:w-full">{selectedData.slide8context}</p>
                    </div>
                  </div>
                </SwiperSlide>
                {selectedData.slide9title?.trim() && selectedData.slide9context?.trim() && (
  <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
    <div className="flex flex-col justify-end h-full">
      <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
        <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]">
          <span className="text-[#063f74] font-semibold">{selectedData.slide9title}</span>
        </h2>
        <p className="w-[89%] sm:w-full">{selectedData.slide9context}</p>
      </div>
    </div>
  </SwiperSlide>
)}
{selectedData.slide10title?.trim() && selectedData.slide10context?.trim() && (
  <SwiperSlide className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${selectedData.slideRightImg})` }}>
    <div className="flex flex-col justify-end h-full">
      <div className="bg-white px-24 py-[4.5rem] w-[60%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[95%] xs:w-[80%]">
        <h2 className="heading-6 font-serif font-medium text-[#333045] mb-[15px]">
          <span className="text-[#063f74] font-semibold">{selectedData.slide10title}</span>
        </h2>
        <p className="w-[89%] sm:w-full">{selectedData.slide10context}</p>
      </div>
    </div>
  </SwiperSlide>
)}

              </Swiper>
              <div className="absolute bottom-0 right-full z-[1] md:right-0">
                <div onClick={() => swiperRef1Fivereasons.current.swiper.slidePrev()} className="h-[62px] w-[62px] leading-[62px] text-lg bg-[#063f74] left-0 text-[#ffffff] transition-default flex justify-center items-center hover:bg-[#ffffff] hover:text-darkgray">
                  <i className="feather-arrow-left"></i>
                </div>
                <div onClick={() => swiperRef1Fivereasons.current.swiper.slideNext()} className="mt-[1px] h-[62px] w-[62px] leading-[62px] text-lg left-0 bg-[#063f74] text-[#ffffff] transition-default flex justify-center items-center hover:bg-[#ffffff] hover:text-darkgray">
                  <i className="feather-arrow-right"></i>
                </div>
              </div>
            </m.div>
          </Row>
        </Container>
      </section>)}
      {/* Service Section End */}
            



            {/* Section Start */}
            <section className="bg-lightgray py-[100px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
                <Container>
                    <Row className="justify-center flex">
                        <m.div className="text-center mb-20 md:mb-12 col-lg-6 col-sm-8" {...fadeIn}>
                            <span class="text-xmd mb-[15px] font-serif block w-full">{selectedData.centerSmallHeading}</span>
                            <h5 class="font-serif text-darkgray font-medium mb-8 sm:w-full">{selectedData.centerBigHeading}</h5>

                        </m.div>
                        <Col xs={12} md={9} lg={12}>
                            <IconWithText
                                grid="row-cols-1 row-cols-md-1 row-cols-lg-2 gap-y-[15px]"
                                theme="icon-with-text-02 about-us-icon-with-text"
                                data={selectedData.IconWithTextData}
                                animation={fadeIn}
                                animationDelay={0.1}
                            />
                        </Col>
                    </Row>
                </Container>
            </section >
            {/* Section End */}

            {/* Section Start */}
            <m.section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:pt-[50px] xs:pb-0" {...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col md={12} className="text-center mb-[6%]">
                            <h6 className="font-serif text-darkgray font-medium">{selectedData.techs}</h6>
                        </Col>
                    </Row>
                    <Services grid="row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-24 md:gap-y-[4rem] justify-center" theme='service-style-04' className="" data={selectedData.serviceData} animation={fadeIn} />
                </Container>
            </m.section>
            {/* Section End */}

            {/* Section Start */}
            <section className="px-[25px] bg-[#f7f7f7] py-[130px] overflow-hidden lg:py-[90px] sm:py-[70px] sm:pb-0 xs:py-[50px] xs:px-0">
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={4} sm={8} className="text-center text-lg-start md:mb-[50px]">
                            <h6 className="font-serif w-[80%] text-darkgray font-medium mb-[20px] lg:w-[90%] md:w-full md:mb-[10px]">{selectedData.process}</h6>
                            <Buttons ariaLabel="button" to="/our-services/" className="text- font-medium font-serif uppercase btn-link after:h-[2px] md:text-md after:bg-fastblue hover:opacity-70" size="md" color="#0038e3" title="Discover Services" />
                        </Col>
                        <TextBox
                            grid="row-cols-1 row-cols-md-2 col-lg-8"
                            theme="text-box-style-02 flex-wrap lg:px-0"
                            className="sm:mb-[30px] xs:last:mb-0"
                            data={selectedData.TextBoxData002.slice(0, 6)}
                            animation={fadeIn} />
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            {/* Section Start */}
            <section className="bg-white py-[80px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container fluid className="px-[12%] xl:px-[10%] lg:px-[3%]">
                    <Row>
                        <Col className="col-12 text-center mb-28">
                            <span className="block font-serif mb-[5px]">Most common questions</span>
                            <h5 className="font-serif text-darkgray font-semibold mb-0">Frequently asked questions</h5>
                        </Col>
                    </Row>
                    <Row className="gap-y-[30px]">
                        {listDataFaq.map((item, i) => {
                            return (
                                <m.div className="col-12 col-lg-4 col-sm-6" key={i} {...fadeIn}>
                                    <div className="faqs-page-list bg-white rounded box-shadow-small p-16 lg:p-12 h-full">
                                        <span className="font-serif text-xmd text-darkgray font-medium block mb-[15px]">{item.title}</span>
                                        <ul className="p-0 list-style text-start mb-[15px]">
                                            {item.content && item.content.map((item, i) => {
                                                return (
                                                    <li key={i}><Link to="/faq" className="hover:text-hasocolor">{item}</Link></li>
                                                )
                                            })}
                                        </ul>
                                        <Buttons ariaLabel="Agency info" href="/faq" className="font-medium font-serif uppercase btn-link !tracking-[.5px] after:h-[2px] after:bg-hasocolor hover:text-hasocolor" size="md" color="#063f74" title="See all articles" />
                                    </div>
                                </m.div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            {/* Footer Start */}
            <FooterStyle03 theme="dark" className="text-[#828282] bg-[#0b0b26] consulting-footer" />
            {/* Footer End */}

        </div>
        {/* <div className="h-card">
            <h1 className="p-name">{selectedData.openGraph?.siteName}</h1>
            <p className="p-description">{selectedData.metaDescription}</p>
            <div className="h-adr">
                <p className="p-street-address">{selectedData.businessContactData?.streetAddress}</p>
                <p>
                    <span className="p-locality">{selectedData.businessContactData?.locality}</span>,{' '}
                    <span className="p-region">{selectedData.businessContactData?.region}</span>,{' '}
                    <span className="p-postal-code">{selectedData.businessContactData?.postalCode}</span>,{' '}
                    <span className="p-country-name">{selectedData.businessContactData?.countryName}</span>
                </p>
            </div>
        </div> */}
        </>
    )
}

export default PageTemplate