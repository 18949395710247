const FooterData = [
    {
        title: "company",
        submenu: [
            {
                title: "About company",
                link: "/about-us"
            },
            {
                title: "Company process",
                link: "/our-process"
            },
            {
                title: "Company services",
                link: "/our-services"
            },
            {
                title: "Portfolio",
                link: "/our-portfolio"
            },
            {
                title: "Career",
                link: "/our-team"
            },
            {
                title: "Contact us",
                link: "/contact-us"
            },
        ]
    },
    {
        title: "Services",
        submenu: [
            {
                title: "MEAN Stack Development",
                link: "/mean-stack-developer-in-canada"
            },
            {
                title: "MERN Stack Development",
                link: "/full-stack-developer-in-canada"
            },
            {
                title: "Mobile App Development",
                link: "/mobile-app-development-company"
            },
            {
                title: "Wordpress Development",
                link: "/wordpress-application-development"
            },
            {
                title: "CRM Software Development",
                link: "/crm-software-development"
            },
            {
                title: "Ecommerce Integration With Lazada & Shopee",
                link: "/ecommerce-integration-with-lazada-shopee"
            },
            {
                title: "Ecommerce integration with Sellers Spec",
                link: "/sellersspec"
            },
            {
                title: "Point Of Sale Application",
                link: "/point-of-scale-application-development"
            },
            {
                title: "Search Engine Optimization",
                link: "/search-engine-optimization"
            },
            {
                title: "Social Media Marketing",
                link: "/social-media-marketing"
            },
            
        ]
    },
    {
        title: "Services",
        submenu: [
            {
                title: "Ecommerce Development",
                link: "/ecommerce-site-builders"
            },
            {
                title: "PHP Development",
                link: "/php-application-development"
            },
            {
                title: "Web Design",
                link: "/website-development-company-in-toronto"
            },
            {
                title: "SAAS Development",
                link: "/software-as-a-service-application-development"
            },
            {
                title: "IT Outsourcing",
                link: "/it-outsourcing-service-providers"
            },
            {
                title: "Laravel Application Development",
                link: "/php-laravel-application-development"
            },
            
            {
                title: "Desktop Application Services",
                link: "/desktop-application-development-services"
            },
            {
                title: "CMS Development",
                link: "/cms-custom-made-software-development"
            },
            {
                title: "Software Testing",
                link: "/software-testing-in-toronto"
            },
            {
                title: "Website Development",
                link: "/web-application-development-company"
            },
            {
                title: "LAMP Development Services",
                link: "/lamp-development-services"
            },
            
        ]
    },
    {
        title: "Services",
        submenu: [
            
            
        ]
    },
    {
        title: "Resources",
        submenu: [
            {
                title: "Theme guide",
                link: "/faq"
            },
            {
                title: "Support desk",
                link: "/faq"
            },
            {
                title: "What we offer",
                link: "/page/what-we-offer"
            },
            {
                title: "Company history",
                link: "/page/our-story"
            },
        ]
    },
    {
        title: "Categories",
        submenu: [
            {
                title: "For men",
                link: "/shop/shop-wide"
            },
            {
                title: "For woman",
                link: "/shop/shop-wide"
            },
            {
                title: "Accessories",
                link: "/shop/shop-wide"
            },
            {
                title: "Collections",
                link: "/shop/shop-wide"
            },
        ]
    },
    {
        title: "Adavantages",
        submenu: [
            {
                title: "Free delivery",
                link: "/faq"
            },
            {
                title: "100 days refund",
                link: "/faq"
            },
            {
                title: "Multiple payments",
                link: "/faq"
            },
            {
                title: "Sustainable",
                link: "/faq"
            },
        ]
    },
]

export default FooterData