const TextBoxData01 = [
  {
    number: "500+",
    title: "Intellect Solutions",
    description: "Explore cutting-edge technologies to provide intellect software solutions for your business."
  },
  {
    number: "150+",
    title: "Client Success Stories",
    description: "Our satisfied clients reflect our commitment to delivering high-quality software products and services."
  },
  {
    number: "50+",
    title: "Expert Development Team",
    description: "With a team of experienced developers, we craft robust and scalable software tailored to your needs."
  }

]

const TextBoxData02 = [
  {
    number: "01",
    title: "Gather Requirements",
    description: "Once the application is confirmed, we gather what requirements are needed for the development. It includes the design, client’s requirements, cost, time."
  },
  {
    number: "02",
    title: "Choose Technology",
    description: "Choosing technology is the most important task. Because based upon our technology we develop your application. We mobile app developers canada  make sure that we will provide the best technology for your application."
  },

  {
    number: "03",
    title: "Make mock UI",
    description: "After getting the required information about the application, we build a dummy model that includes the application’s functionality in all aspects."
  },
  {
    number: "04",
    title: "Define Rest APIs or Direct Implementation",
    description: "To make a standardized architecture style for creating a Web Service API we use Rest APIs and sometimes we use direct implementation."
  },
  {
    number: "05",
    title: "Development",
    description: "The development of the application according to the client’s needs. We develop applications with creative designs."
  },
  {
    "number": "06",
    "title": "Automation Testing",
    "description": "We offer comprehensive automation testing services to ensure the quality and reliability of your software. Allowing for faster release cycles and improved overall efficiency."
  }


]

export { TextBoxData01, TextBoxData02 }