/** Custom web application development services */

export const pageData1 = {
    "aquarius-2": { //url
        title: "Aquarius",
        subTitle: "Tailored Solutions for Your Business",
        heading: "Aquarius-2 by",
        headingName: "HasoTech",
        aboutContent: "Field Management is hard to maintain by a person. We solve this crisis by developing an ionic and angular-based Mobile and Web application. With this application, it is easy to maintain the staff report.",
        centerBigHeading: "Why Aquarius",
        centerSmallHeading: "",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/aqua.png",
        listData: [
            {
                icon: "fas fa-check",
                content: "This application shows the list of the client along with their location.Once you choose the client and location you’ll get the list of worker under it. By getting the location automatically you will able to avoid confusion and easy to monitor the staff of the particular location."
            },
            {
                icon: "fas fa-check",
                content: "The checklist provides the to-do list for the staff. The staff can mark or can describe the work they have done. It helps the staff to complete their task and keeping them more organized assuring them not to skip their work."
            },
            {
                icon: "fas fa-check",
                content: "The staff name can be added. The list of the staff will be viewed and you can also edit, view, and delete the staff name. This helps you to maintain the staff according to the work."
            },
            {
                icon: "fas fa-check",
                content: "The staff can update their work. The reports can be uploaded in the form of image,signature, audio. The report of the staff can be uploaded in both mobile and web application. The Report can be downloaded, searched in the Web admin portal."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy Maintance.",
                content: "",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Aviod the skipping on work",
                content: "",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Aviod the skipping on work.",
                content: "",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Make your work more clear and efficient for client.",
                content: "",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** True Id */

    "true-id": { //url
        title: "True ID",
        subTitle: "Identifying the unknown number must be challenging, thus you need a True ID application.",
        heading: "True ID by",
        headingName: "HasoTech",
        aboutContent: "Can you remember the phonebook days? Where each number was identified a people could quickly communicate with others on the white pages? The smartphone world altered the telephone book definition and made it harder to find and reconcile unfamiliar callers with the knowledge that you have forgotten.",
        centerBigHeading: "Advantages Of True ID",
        centerSmallHeading: "",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/truid.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "In the Contact forum, the user can add or delete a contact. Even they can edit their contacts. The most important part of this feature is that the unknown number can be searched"
            },
            {
                icon: "fas fa-check",
                content: "Once the application is installed the contacts from the user will be imported to this.Where they can view their contact in this application. You can ban the contact that you don’t need and also contacts can be marked as Spam so you’ll be aware of the contact."
            },
            //      {
            //           icon: "fas fa-check",
            //           content: "The staff name can be added. The list of the staff will be viewed and you can also edit, view, and delete the staff name. This helps you to maintain the staff according to the work."
            //      },
            //      {
            //        icon: "fas fa-check",
            //        content: "The staff can update their work. The reports can be uploaded in the form of image,signature, audio. The report of the staff can be uploaded in both mobile and web application. The Report can be downloaded, searched in the Web admin portal."
            //   }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Easy Maintance.",
                content: "Awesome data sources – The immediate benefit of a True ID application is how incoming calls and text messages are identified. So that ignore or even block future calls from the number",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Aviod the skipping on work",
                content: "ID for both text and calls – It provides information on incoming calls and messages and provides full knowledge of all correspondence types.",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Aviod the skipping on work.",
                content: "Block unwanted callers – You should also be able to decide which calls you to get by having more power over your calls.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Make your work more clear and efficient for client.",
                content: "Detect and avoid spam – It also allows you to know if the individual or organization attempting to contact your phone has already been identified as a telemarketer, scammer, or an irritating caller",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** Uniqpos */

    "uniqpos": { //url
        title: "Uniqpos",
        subTitle: "Managing the entire store must be difficult that’s why we design Uniq POS. Easily maintain your business with this application.",
        heading: "Uniqpos by",
        headingName: "HasoTech",
        aboutContent: "Uniq POS is a web application that makes doing business simple for you. We offer easy-to-use sales functions, allowing you to quickly and log the sales process and when the internet goes offline, Uniq POS will continue to sell instantly when you return online.",
        centerBigHeading: "Why Need Uniq POS?",
        centerSmallHeading: "",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/uniqpos.png",
        listData: [
            {
                icon: "fas fa-check",
                content: "Uniq POS application can be used by multiple domains under the control of Super Admin. This feature helps to maintain the numerous subunit you have for your business. The Admin POS and eCommerce have separate multi-domains."
            },
            {
                icon: "fas fa-check",
                content: "Your work will not be paused when you are offline. This is the main advantage of using Uniq POS. This application will work as an offline POS."
            },
            {
                icon: "fas fa-check",
                content: "Along with the POS application, you get eCommerce for your business. This eCommerce feature allows you to lookup up your stocks and syncs the product.The eCommerce can be Integrated/Configurated with Lazada, Shopee."
            },
            {
                icon: "fas fa-check",
                content: "At the yearly renewal, you can increase or decrease the POS system according to your business need. User and POS terminal packages can be upgraded and downgraded."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Quick keys and/or product lookup",
                //   content: "Awesome data sources – The immediate benefit of a True ID application is how incoming calls and text messages are identified. So that ignore or even block future calls from the number",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Multiple payment methods.",
                //   content: "ID for both text and calls – It provides information on incoming calls and messages and provides full knowledge of all correspondence types.",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy maintenance",
                //   content: "Block unwanted callers – You should also be able to decide which calls you to get by having more power over your calls.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Able to work offline",
                //   content: "Detect and avoid spam – It also allows you to know if the individual or organization attempting to contact your phone has already been identified as a telemarketer, scammer, or an irritating caller",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** rmc-ready-mix-concrete */

    "rmc-ready-mix-concrete": { //url
        title: "RMC (Ready Mix Concrete)",
        subTitle: "A CRM sales application for managing the production, sales, delivery, and orders. RMC is the Web and mobile application.",
        heading: "Uniqpos by",
        headingName: "HasoTech",
        aboutContent: "RMC is a complete CRM project to exclusive sales app for the RMC segment enabling the salesperson to record his activity. This includes registering customer data for placing orders, invoicing details, and scheduling with their factory. This communication makes the delivery process a streamlined systematic way of doing things.",
        centerBigHeading: "RMC Web Application",
        centerSmallHeading: "",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/pmsCon.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "You can restrict the login time from when the user can log in to the organization.The login time will be restricted according to your convenience. For example, the time will be restricted from 7 am to 9 pm."
            },
            {
                icon: "fas fa-check",
                content: "The calls are divided into four categories. New Calls, Scheduled Calls, Cold Calls,Rescheduled Calls. With this feature, you’ll differentiate the customer and won’t miss the customer call because it can be rescheduled according to their available time."
            },
            {
                icon: "fas fa-check",
                content: "The order made will be recorded in this. It is easy to know about the orders placed and to be placed. There is reschedule order is along with this feature, the order can be rescheduled or can be reordered. The delivery status of the order and delivered order will be updated."
            },
            {
                icon: "fas fa-check",
                content: "Once the order is delivered the signature of the receiver will be received. By this, you can check whether the order is placed with the right person. The order misplacement will be reduced."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Quick keys and/or product lookup",
                content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Multiple payment methods.",
                content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Easy maintenance",
                content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                //   title: "Able to work offline",
                content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** quill */

    "quill": { //url
        title: "Quill",
        subTitle: "Finding the details of the person must be difficult. Through Quill Search, you can easily find the details you need.",
        heading: "Quill by",
        headingName: "HasoTech",
        aboutContent: "Connect and search people all over the world in a single touch. It can be used for individuals or enterprises. It is highly reliable and convenient to use for organizations. For each organization, the E-visiting card provides the personnel,professional and social information in one place with the organization profile picture.",
        centerBigHeading: "Advantage Of Quill Search",
        centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/callerfinder.png",
        listData: [
            {
                icon: "fas fa-check",
                content: "The profile allows the user to edit their details like mobile number, name and their company detail. This allows the user to create their E-visiting card."
            },
            {
                icon: "fas fa-check",
                content: "This search bar makes users find the details of the person or the organization or the company they want. They can find the details just by entering the number or the name"
            },
            {
                icon: "fas fa-check",
                content: "Users not only can make calls but also they can chat through this. The call log of the user consists of the called numbers with time."
            },
            {
                icon: "fas fa-check",
                content: "Users can upload the photo of their organization or company. They can choose whether their image wants to be in public or private."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Find the Unknown number in no time",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Block the unwanted calls.",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Avoid spam calls.",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Search Businesses",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** Madurai Children Hospital */

    "madurai-children-hospital-web-application": { //url
        title: "Madurai Children Hospital",
        subTitle: "Manage the children’s vaccination online and make an appointment online. We make this possible by deploying a Web application.",
        heading: "Madurai Children Hospital by",
        headingName: "HasoTech",
        aboutContent: "This application specifically is used in hospitals to maintain the patient’s vaccination records, which is fairly significant. This application includes the SMS report, particularly the Weekly report, Patient’s details, and QR scanner for Online appointments. Madurai children’s Hospital application is deployed for both web application and website. The web application maintains the patient’s details and their reports. Whereas websites used to get online appointments.",
        centerBigHeading: "Why Need Madurai Children Hospital Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/drc.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "The patient’s details include the Name of the patient, Date of birth, mobile number, and their parent’s details. These will be used for patient registration. The appointments are made along with these details. This detail helps the hospital to maintain the patient’s record."
            },
            {
                icon: "fas fa-check",
                content: "The SMS trigger is to remind the patient about their vaccination. The SMS trigger will be recorded in the SMS log. The SMS log includes the number of the patient, contact number, and the date of the SMS sent"
            },
            {
                icon: "fas fa-check",
                content: "The Weekly report can be viewed from date to date. This helps you to maintain the patient’s report for a week. The weekly report enhances your performance to maintain the patient’s record in an easy way. The weekly record can be viewed at any time. You can get the patient’s record in no time."
            },
            {
                icon: "fas fa-check",
                content: "Appointments can be made online through the website. Online appointments can be done easily just by scanning the QR code. This makes it easy for the patients to make appointments. Through the website, you’ll be apparent to the people so they can approach the hospital. This makes an assurance to the people."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Patient’s Details",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "SMS Trigger",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Weekly Report",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Website",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


    /** Restaurant POS */

    "restaurant-pos-application": { //url
        title: "Restaurant POS",
        subTitle: "Managing the restaurant or the cafeteria is almost tiring thus we introduce  Restaurant POS which helps you to make the management easier and add profit to your business.",
        heading: "Restaurant POS by",
        headingName: "HasoTech",
        aboutContent: "Restaurant POS is a web application that is a complete point of sale software to manage your daily restaurant operations. It is designed to serve and assist the Food and Beverage (F&B) industry, restaurant management expedites every operation of the restaurant. In this application, you can manage your restaurant’s easy billing, order management, dining management, managing the stock, and an eCommerce site. The main advantage of this application is it is bidirectional.",
        centerBigHeading: "Why Need Restaurant POS Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/rest.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "Taking order manually takes your time and chances of making mistake is huge. But when the order is placed through an eCommerce application, saves your time. Order is directly sent to the kitchen server once it was taken. The order will be sent along with the table number"
            },
            {
                icon: "fas fa-check",
                content: "Restaurant POS eCommerce application is available for android. In this application after login, you will see the home page consists of a number of floors with an available table so it’ll be easy to know your availability. The orders will be taken through this application."
            },
            {
                icon: "fas fa-check",
                content: "The customers can scan the QR code from the restaurant so that they can place the order along with the table number by themselves. Once the QR code is scanned you’ll be directed to the Food and beverages listed in categories and place the order in no time."
            },
            {
                icon: "fas fa-check",
                content: "The payment can be done both online and in cash. The online payment is done by HitPay. Your payment will be done in a quick and secure way. "
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Multiple Device Synchronization",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Multiple Order Type Selection Modes And Screens.",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy Item Modifications And Updates.",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Quick Order Lookup.",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


    /** /mrns-a-web-and-mobile-application/ */

    "mrns-a-web-and-mobile-application": { //url
        title: "MRNS",
        subTitle: "Calculating Laden and Unladen weight in the vehicle for the construction site might be difficult. We make it easy for you by providing a Web and mobile applications",
        heading: "MRNS by",
        headingName: "HasoTech",
        aboutContent: "The MRNS application is to maintain the work on the construction site. This is a web and mobile application that includes the records of laden and unladen weight, material grade, material categories, thermal print, syncing data online. The mobile application is deployed for the operator, supervisor whereas the web application is for the admin who has control over the operator and supervisor.  This application is mainly to maintain the record and to calculate the total weight. This application makes your work easy on the construction site.",
        centerBigHeading: "Why Need MRNS Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/pms.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "The operator can create a new record to maintain the material, vehicle number, supplier name, laden and unladen weight, quantity. By this, the record can be created without any error. These records can be viewed at any time."
            },
            {
                icon: "fas fa-check",
                content: "The records from the operators will be synced to them. They can view the record at any time. They get all records submitted by the operators. This feature will be useful for them to maintain the record for checking."
            },
            {
                icon: "fas fa-check",
                content: "The web application is deployed for the admin who can maintain the whole. It includes the operators, supervisors, materials, and projects. They can manage these with the web application. They can save time and can maintain the report at any time. "
            },
            //      {
            //        icon: "fas fa-check",
            //        content: "The payment can be done both online and in cash. The online payment is done by HitPay. Your payment will be done in a quick and secure way. "
            //   }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy Way To Calculate The Total Weight.",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy Billing.",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Avoid Manual Errors.",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Sync The Data Automatically Or Manually.",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** beautyconnect-native-applicaiton/ */

    "beautyconnect-native-applicaiton": { //url
        title: "BeautyConnect - Native Application",
        subTitle: "BeautyConnect is an easy-to-use native mobile application with extensive features for everyone",
        heading: "BeautyConnect by",
        headingName: "HasoTech",
        aboutContent: "BeautyConnect is an easy-to-use native mobile application with extensive features for everyone. Using BeautyConnect, you’ll be able to access beauty parlors and other beauty care services from the comfort of your home. Get groomed with BeautyConnect mobile application.",
        centerBigHeading: "Why Need BeautyConnect Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/btyconnect.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "Book Your Appointment"
            },
            {
                icon: "fas fa-check",
                content: "Parlor Services"
            },
            {
                icon: "fas fa-check",
                content: "Parlor Lists"
            },
            {
                icon: "fas fa-check",
                content: "Payment"
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy accessible.",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Get more visible to customers nearby your parlor.",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Save time by booking online.",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Connect with customers 24/7",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** aldeazon-ecommerce-application */

    "aldeazon-ecommerce-application": { //url
        title: "Aldeazon",
        subTitle: "Aldeazon is an eCommerce mobile and web application",
        heading: "Aldeazon by",
        headingName: "HasoTech",
        aboutContent: "Aldeazon is an eCommerce mobile and web application that allows users to purchase their agricultural commodities. The mobile application includes the category, shop, orders, shipping details, and wish list. Aldeazon web application includes the Admin Portal, Procurement Portal shows all orders, Supplier Portal, Credit Officer Portal for payment detail, and Transport Portal contains the delivery detail and driver detail.",
        centerBigHeading: "Why Need Aldeazon Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/webp/ecom.webp",
        listData: [
            {
                icon: "fas fa-check",
                content: "Home Page"
            },
            {
                icon: "fas fa-check",
                content: "Category Page"
            },
            {
                icon: "fas fa-check",
                content: "Shop"
            },
            {
                icon: "fas fa-check",
                content: "Cart & Wish list Page"
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Increase your sales on agricultural commodities",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Attract global customers",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy checkout",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy product management",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** Alive */

    "alive": { //url
        title: "Alive",
        subTitle: "ALIVE is an online Gym search mobile application, in which users can search for nearby gyms and Fitness centers, users can pay fees online",
        heading: "ALIVE by",
        headingName: "HasoTech",
        aboutContent: "ALIVE is a mobile application that allows users to search the nearest gym. In this mobile application, you can search gym near your location. This application makes your gym more visible to the customers. ALIVE mobile application is a hybrid mobile application. Users can get information about the gym by simply searching it. They can also book their session by choosing the session they want with online payment.",
        centerBigHeading: "Why Need ALIVE Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/alive.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "Users can log in to the alive mobile application with their mobile number. Using your login to keep an internal profile of each customer is a wonderful method to customize your assistance."
            },
            {
                icon: "fas fa-check",
                content: "Alive category page includes the services provided by the gyms. The services are split up into two categories economic and premium. The users can choose the category according to their needs. This makes your service more personalized for users."
            },
            {
                icon: "fas fa-check",
                content: "App search is useful because it allows your users to easily access all of the material you provide. Through the search page, you can easily analyze the most searched gym or most searched services. This way you’ll be able to know what users prefer."
            },
            {
                icon: "fas fa-check",
                content: "ALIVE gym filter gives easy access to choose the services you need. This filter includes the category (economic and premium) and the services like the exercise tools (treadmill, cycling). Rooms infrastructure (a.c and non a.c)."
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Gives a more personalized experience.",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "User-friendly application",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "More visible to customers",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Secured online payment",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


    /** Hackeron */

    "hackeron": { //url
        title: "Hackeron",
        subTitle: "Hackeron is a web application for online examinations for students.",
        heading: "Hackeron by",
        headingName: "HasoTech",
        aboutContent: "Hackeron is a hybrid web application deployed customized for online examination. This application allows the users to access the online examinations which include the institute details, question-answer details, class details reports, etc. Hackeron web application has two web portals one is the staff to make questions, make class reports, analyze the result, etc. The other is for students to attend the online examination.",
        centerBigHeading: "Why Need Hackeron",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/hackeron.jpg",
        listData: [
            {
                icon: "fas fa-check",
                content: "The dashboard of the staff portal helps you to get a quick detail of institutions, members, and the number of exams. With this dashboard, you’ll get a quick view of the overall. This provides on-demand access to all of your most important metrics and an enhanced preview of the metrics."
            },
            {
                icon: "fas fa-check",
                content: "This allows the user to add the upcoming exam and the scheduled exams. The exam will be added with the name, date, and duration of the exam. The scheduled exam can be viewed by the user. This includes the exam type like MCQ and Drag & Drop."
            },
            {
                icon: "fas fa-check",
                content: "The question page is where users can add the questions for the exams. It is a simple way to prepare the question. All you need is to select the exam and paste or type the question according to the exam types."
            },
            {
                icon: "fas fa-check",
                content: "This page is for the staff where they can add the staff and view the list of staff. The staff can be adding by with their name, username, password, email id, and the institute they work. Each staff has their username and password"
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Avoid manual errors in results.",
                //   content: "The admin web application has all the control over the sales department and deliveries. It makes it easy to maintain the customers, sales, and deliveries. Since it collects the customer data and holds the consolidated reports.",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy monitoring.",
                //   content: "The Target for the salesperson will be allocated through the web application. The sales and the salesperson will be under monitoring of admin. The admin can view the individual performance by the reports in a mobile application through the web application",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Conduct online examinations without any difficulties.",
                //   content: "The delivery and vehicles are updated in the web application. The type of vehicle and the delivery person will be updated through the web application. The admin can view the total number of users and customers. Each branch has a separate admin web application. They can only monitor their branch.",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy maintenance of exams and results.",
                //   content: "They can monitor the orders like delivered orders, scheduled orders, the orders in process, and canceled orders through this. Customer data will be saved in this so the order can be reordered. For each order placed the bill will be generated. The bill can be both enabled and disabled while generating.",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** my-dicine-customized-mobile-application */

    "my-dicine-customized-mobile-application": { //url
        title: "My Dicine",
        subTitle: "My Dicine is an online medical store customized mobile application used to buy pharmacy items online",
        heading: "Dicine by",
        headingName: "HasoTech",
        aboutContent: "My Dicine is a customized mobile application for e-Pharmacies that allows users to get medicine online at any time anywhere. This application allows the user to register their pharmacy with simple steps. With this application, they will be able to serve the customers at large. My Dicine mobile application is a hybrid mobile application. With the development of e-Pharmacies, purchasing medications has become easier. Users can get register their medical shop and sell their medicine online. This healthcare app lets you register your licensed retail pharmacies and make them sell online.",
        centerBigHeading: "Why Need Dicine",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/webp/hms.webp",
        listData: [
            {
                icon: "fas fa-check",
                content: "My Dicine is one of the most intellect and well-designed online pharmacy applications. This customized mobile application saves time by using our super-fast search bar to order medicines without having to go through the whole catalog, examine the medicine’s full composition, and search for medicines."
            },
            {
                icon: "fas fa-check",
                content: "This application brings all of your locations under one digital range and delivers a consistent user experience across all of your pharmacies. Develop a smooth online prescription ordering and delivery app platform to boost the ROI of your existing pharmacy business."
            },
            {
                icon: "fas fa-check",
                content: "With this application Never be without your essential medications. Customers can opt to have their prescriptions automatically refilled, ensuring that they never run out. Refill schedules can be changed by customers. My dicine online pharmacy is developed to offer your retail store a fresh appearance and attract more clients, allowing you to grow your business."
            },
            // {
            //     icon: "fas fa-check",
            //     content: "This page is for the staff where they can add the staff and view the list of staff. The staff can be adding by with their name, username, password, email id, and the institute they work. Each staff has their username and password"
            // }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                // title: "Avoid manual errors in results.",
                content: "Increased access to medications for those who are restricted to their homes",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                // title: "Easy monitoring.",
                content: "The convenience of comparing many sites to find the best pricing and items",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                // title: "Conduct online examinations without any difficulties.",
                content: "Bring all your branches under one digital range",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                // title: "Easy maintenance of exams and results.",
                content: "Instead of counting pills, pharmacists can focus on the therapeutic requirements of their patients",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


    /** car-wash */

    "carwash-mobile-application": { //url
        title: "Carwash - Mobile Application",
        subTitle: "Provide easy access to your customers with nearby branches of your service",
        heading: "Carwash - Mobile Application by",
        headingName: "HasoTech",
        aboutContent: "Car Wash is a mobile application used for your business to provide better customer engagement and easy access to reach the nearby location. This application makes your business more apparent to the customers. This application is deployed for car washing services. This allows your location to the customers in search of car wash service. The advanced feature makes it easy to use. This includes the search of service centers in a nearby location, online payment, subscription management, and vehicle detail.",
        centerBigHeading: "Why Need CarwashApplication",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/webp/bann.webp",
        listData: [
            {
                icon: "fas fa-check",
                content: "A list of car services at your location will be shown. The list helps your business more visible to the customers. The car service centers at a particular location will be listed through registration. This will provide easy access to your customers to reach your branch near them"
            },
            {
                icon: "fas fa-check",
                content: "The payment has both online and offline methods. This makes the customer more comfortable and convenient. It’s easy to set up an online payment option, and you may start selling within minutes of doing so. Customers today tend to trust retailers who accept online payments more than those who don’t"
            },
            {
                icon: "fas fa-check",
                content: "Subscriptions plans are for 1 year with a code generator. This subscription plan helps you to hold your customer for a long time. You can able build a good bond with the customers"
            },
            {
                icon: "fas fa-check",
                content: "You’ll be visible to more customers near your location. It gives better and easy access to your customers. It’ll be more convenient for the customer to access the nearby center for their services"
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "More apparent to customers",
                //   content: "Increased access to medications for those who are restricted to their homes",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy online payments method",
                //   content: "The convenience of comparing many sites to find the best pricing and items",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy subscription maintenance",
                //   content: "Bring all your branches under one digital range",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Better customer engagement",
                //   content: "Instead of counting pills, pharmacists can focus on the therapeutic requirements of their patients",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


    /** Amaz4u – eCommerce Web And Mobile Application */

    "amaz4u-ecommerce-web-and-mobile-application": { //url
        title: "Amaz4u – eCommerce Web And Mobile Application",
        subTitle: "This is a hybrid application where users can purchase their products",
        heading: "Amaz4u – eCommerce Web And Mobile Application by",
        headingName: "HasoTech",
        aboutContent: "Amaz4u is an eCommerce mobile and web application that allows users to shop. In this mobile application, you can shop by choosing the category, home page contains the offers and other categories, there will be an easy checkout process and payment method. Amaz4u web application has the same features as the mobile application. Having both mobile and web application for an eCommerce increase your customer rate and increase your customer engagement",
        centerBigHeading: "Why Need Amaz4u Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/webp/bann.webp",
        listData: [
            {
                icon: "fas fa-check",
                content: "The home page of this application navigates the user to the Category page, Cart page. The home page will show some of the latest offers and deals and categories"
            },
            {
                icon: "fas fa-check",
                content: "This page makes it easier for the customer to choose the product they want in a short time. With this feature, you can boost up your sales and make your products more apparent to your customers"
            },
            {
                icon: "fas fa-check",
                content: "The Cart page is where users can view their product to be purchased. The users may add items to their shopping cart and then pay online. With this cart page, you can get to know about the user’s preference"
            },
            {
                icon: "fas fa-check",
                content: "Payment can be done both online and offline. The payment process is simple and more secure. Once the payment is done user will get the confirmation notification to confirm their order"
            }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "More apparent to customers",
                //   content: "Increased access to medications for those who are restricted to their homes",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy online payments method",
                //   content: "The convenience of comparing many sites to find the best pricing and items",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy subscription maintenance",
                //   content: "Bring all your branches under one digital range",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Better customer engagement",
                //   content: "Instead of counting pills, pharmacists can focus on the therapeutic requirements of their patients",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },

    /** prime-card-an-on-demand-mobile-application */

    "prime-card-an-on-demand-mobile-application": { //url
        title: "Prime Card",
        subTitle: "Prime Card is an on-demand mobile application used for hotels near your location or at your current location",
        heading: "Prime Card by",
        headingName: "HasoTech",
        aboutContent: "Prime Card is an on-demand Mobile Application that is mainly developed for hotels to make them visible for the customers by allowing the user to find the hotels near them with the location. With this application, you can make your hotel more apparent to your customer. Prime Card has a unique feature on login. It provides biometric authentication with fingerprint and face id unlock. Along with biometric authentication, this application allows you to refer a friend, offer a gift card, and show the hotels in both map view and list view",
        centerBigHeading: "Why Need Prime Card Application",
        // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
        techs: "Cutting-Edge Technologies for Web and Mobile App Development",
        process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
        imgBody: "/assets/img/webp/bann.webp",
        listData: [
            {
                icon: "fas fa-check",
                content: "After the sign-in users can log in with the user id and password or they can use biometric authentication. The biometric authentication used in this application is fingerprint and face id. This increases the security of the Prime Card application. It gives a new user experience to the users. You can also log in as a guest"
            },
            {
                icon: "fas fa-check",
                content: "You can search the hotel according to your current location and you can search by nearby location. You can view the hotels in two ways either by map view or by list view. Registering your hotel in this application makes your business more visible to the customers"
            },
            {
                icon: "fas fa-check",
                content: "Users can refer a friend to this application and both of them can get rewards. This feature gives a major advantage by attracting customers. You can refer a friend with a simple procedure by proving their name, mail id, number"
            },
            // {
            //     icon: "fas fa-check",
            //     content: "Payment can be done both online and offline. The payment process is simple and more secure. Once the payment is done user will get the confirmation notification to confirm their order"
            // }
        ],
        TextBoxData002: [
            {
                number: "01",
                title: "Understand Web Application Requirements",
                description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
            },
            {
                number: "02",
                title: "Select the Optimal Framework for Web Applications",
                description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
            },
            {
                number: "03",
                title: "Create Mock UI for Web Application",
                description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
            },
            {
                number: "04",
                title: "Implement Web Application APIs or Direct Integration",
                description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
            },
            {
                number: "05",
                title: "Develop the Web Application Platform",
                description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
            },
            {
                number: "06",
                title: "Conduct Automation Testing for Web Applications",
                description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
            }
        ],
        IconWithTextData: [
            {
                icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Easy way to find a hotel near you",
                //   content: "Increased access to medications for those who are restricted to their homes",
            },
            {
                icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Makes your hotel more apparent to your customer",
                //   content: "The convenience of comparing many sites to find the best pricing and items",
            },
            {
                icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Rewards to hold your customer for a long time",
                //   content: "Bring all your branches under one digital range",
            },
            {
                icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                title: "Advanced Biometric authentication",
                //   content: "Instead of counting pills, pharmacists can focus on the therapeutic requirements of their patients",
            }
        ],
        serviceData: [
            {
                img: "/assets/img/webp/reactjs.webp",
                title: "React Framework"
            },
            {
                img: "/assets/img/webp/nodejs.webp",
                title: "NodeJs"
            },
            {
                img: "/assets/img/webp/mysql.webp",
                title: "MySQL"
            },
            {
                img: "/assets/img/webp/mongo.webp",
                title: "MongoDB",
            },
            {
                img: "/assets/img/webp/angularr.webp",
                title: "Angular"
            },
            {
                img: "/assets/img/webp/aws.webp",
                title: "Amazon Web Services (AWS)"
            },
        ]

    },


        /** travel-booking-hybrid-application */

        "travel-booking-hybrid-application": { //url
            title: "Travel Booking",
            subTitle: "Managing the travels manually must be hard. We provide the solution to ease your work with a hybrid application",
            heading: "Travel Booking by",
            headingName: "HasoTech",
            aboutContent: "Travel booking is a user’s manual booking system. This application helps you in booking, maintaining drivers, and admin using the application. This application includes Booking details, Driver details, Reports, booking history. This application is deployed with a Hybrid application. This application is for both web and mobile. This application is highly scalable and performing. The advanced feature in this application makes this easy for you to manage your business",
            centerBigHeading: "Why Need Travel Booking Application",
            // centerSmallHeading: "We people are using a smartphone which is very helpful for daily life. We can search for anything in this world within a second.",
            techs: "Cutting-Edge Technologies for Web and Mobile App Development",
            process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
            imgBody: "/assets/img/webp/bann.webp",
            listData: [
                {
                    icon: "fas fa-check",
                    content: "This feature helps to book the service for the company. With this feature, you can easily book the vehicle for your guests and allocate the driver. The details of the booking will be displayed along with the company name, contact number, and name of the person. You can view the previous booking. This helps you to maintain the guest’s booking history"
                },
                {
                    icon: "fas fa-check",
                    content: "The booking details include the guest’s name, contact number, booking date and time, pickup and drop location. This also includes the driver’s detail like name, contact number, mobile number, vehicle number, and model of vehicle. The detail can be sent to the guest through SMS. These details can be edited and viewed which avoids confusion"
                },
                {
                    icon: "fas fa-check",
                    content: "The export details can be downloaded just with the detail of date, company name, vehicle number, and driver’s name. With this, it helps you won’t miss any of the details of your guests"
                },
                {
                    icon: "fas fa-check",
                    content: "The booking history of the guests includes name, contact number, booking date, booking time, pickup, and drop location. With this, you can view your guest’s booking at any time"
                }
            ],
            TextBoxData002: [
                {
                    number: "01",
                    title: "Understand Web Application Requirements",
                    description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
                },
                {
                    number: "02",
                    title: "Select the Optimal Framework for Web Applications",
                    description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
                },
                {
                    number: "03",
                    title: "Create Mock UI for Web Application",
                    description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
                },
                {
                    number: "04",
                    title: "Implement Web Application APIs or Direct Integration",
                    description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
                },
                {
                    number: "05",
                    title: "Develop the Web Application Platform",
                    description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
                },
                {
                    number: "06",
                    title: "Conduct Automation Testing for Web Applications",
                    description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
                }
            ],
            IconWithTextData: [
                {
                    icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Easy maintenance on booking details",
                    //   content: "Increased access to medications for those who are restricted to their homes",
                },
                {
                    icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Download the export report anytime",
                    //   content: "The convenience of comparing many sites to find the best pricing and items",
                },
                {
                    icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Avoid confusion between customers",
                    //   content: "Bring all your branches under one digital range",
                },
                {
                    icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "View the previous travel details",
                    //   content: "Instead of counting pills, pharmacists can focus on the therapeutic requirements of their patients",
                }
            ],
            serviceData: [
                {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
                },
                {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
                },
                {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
                },
                {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
                },
                {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
                },
                {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
                },
            ]
    
        },
};
