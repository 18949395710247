const ClientData01 = [
    {
        img: '/assets/img/webp/gcpa.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/drravi.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/vel.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/abilogo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/beautyconnect.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/netsuite.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ink.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/attune.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/aventria.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/a2000.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/lion.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/xlr.webp',
        target: "_self",
        link: '#'
    },


    {
        img: '/assets/img/webp/whar.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/altimus.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ckslogo.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/webp/triangle.webp',
        target: "_self",
        link: '#'
    },
]

const ClientData02 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData03 = [
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    }
]

const ClientData04 = [
    
    {
        img: 'assets/img/sap-erp.webp',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/woocommerce.svg',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/flipkart-logo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/lazada-logo.png',
        target: "_self",
        link: '#'
    },
    
   
    {
        img: 'assets/img/oracle-logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/Dynamics-365-Logo.png',
        target: "_self",
        link: '#'
    },
     {
        img: 'assets/img/Shopify-Logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/magento--logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/zoho--logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/amazon-logo-.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/intuit-quickbooks-logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/big-commerce-logo-.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/shopee--logo.png',
        target: "_self",
        link: '#'
    },
    {
        img: 'assets/img/and+more.png',
        target: "_self",
        link: '#'
    }
    
]


const ClientData05 = [
    {
        img: '/assets/img/3rd_party/oracle.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/amazon.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/Flipkart-Logo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/Lazada_logo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/agora.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/stripe-logo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/Razorpay_logo.svg',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/graphhopper.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/shopify.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/shopee.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/qoo10.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/3rd_party/nsdl.webp',
        target: "_self",
        link: '#'
    },
    
    {
        img: '/assets/img/3rd_party/ariba.jpg',
        target: "_self",
        link: '#'
    },
    
    {
        img: '/assets/img/3rd_party/paypal.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/gmap.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/OSM_logo.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/3rd_party/ipay88.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/digilocker.jfif',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/cdsl.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/3rd_party/karvy.webp',
        target: "_self",
        link: '#'
    },
    
]

const ClientData06 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
]

const ClientData07 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData08 = [
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    }
]


export { ClientData01, ClientData02, ClientData03, ClientData04, ClientData05, ClientData06, ClientData07, ClientData08 };