import React from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { m } from "framer-motion";
import { Link } from 'react-router-dom'
// import { SwiperSlide, Swiper } from 'swiper/react';
import { Parallax } from 'react-scroll-parallax'
// import { Keyboard } from "swiper";
// import { Link as ScrollTo } from "react-scroll"

// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import Lists from '../../Components/Lists/Lists'
import Buttons from '../../Components/Button/Buttons'
import Services from '../../Components/Services/Services';
// import CustomModal from '../../Components/CustomModal'
// import CustomIconWithText from '../../Components/CustomIconWithText/CustomIconWithText'
import { fadeIn } from '../../Functions/GlobalAnimations';
// import PricingTable04 from '../../Components/PricingTable/PricingTable04';
// import Testimonials from '../../Components/Testimonials/Testimonials';
// import Clients from '../../Components/Clients/Clients';
// import FooterStyle01 from '../../Components/Footers/FooterStyle01';
// import Overlap from '../../Components/Overlap/Overlap';
import SideButtons from "../../Components/SideButtons";
import FooterStyle03 from '../../Components/Footers/FooterStyle03'
// import PortfolioClassic from "../../Components/Portfolio/PortfolioClassic";
import IconWithText from '../../Components/IconWithText/IconWithText'

import { Swiper, SwiperSlide } from "swiper/react";

// Data
import { listDataFaq } from '../Home/Consulting';
import { serviceData34 } from '../../Components/Services/ServicesData'
import { Autoplay, Keyboard, Navigation } from 'swiper';
import Clients from '../../Components/Clients/Clients';
import {

    ClientData04,

  } from "../../Components/Clients/ClientsData";

  
//Data
import { IconWithTextData_10 } from '../../Components/IconWithText/IconWithTextData'

// import { TestimonialsData02 } from '../../Components/Testimonials/TestimonialsData';
// import { CustomIconWithTextData2 } from '../../Components/CustomIconWithText/CustomIconWithTextData';
// import { serviceData5 } from '../../Components/Services/ServicesData';
// import { pricingTable04 } from '../../Components/PricingTable/PricingTableData';

const TestimonialsCarouselData = [
    {
      img: "/assets/img/webp/1.webp",
      title: "Unlimited power customization",
      number: "01",
      content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt elit.",
    },
    {
      img: "/assets/img/webp/2.webp",
      title: "Powerful creatives designer",
      number: "02",
      content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt elit.",
    },
    {
      img: "/assets/img/webp/3.webp",
      title: "Advanced customization options",
      number: "03",
      content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt elit.",
    },
    {
        img: "/assets/img/webp/4.webp",
        title: "Advanced customization options",
        number: "03",
        content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt elit.",
      },
  ]
const ListData1 = [
    {
      icon: "feather-arrow-right-circle",
      content: "Request a demo today and see our integration solution in action."
    },
    {
      icon: "feather-arrow-right-circle",
      content: "Discover how effortless integration can transform your business and drive success.",
    },
    {
      icon: "feather-arrow-right-circle",
      content: "Don't miss out on this opportunity to revolutionize your e-commerce operations.",
    },
  ]
const listData = [
    {
        icon: "fas fa-check",
        content: "Gone are the days of juggling multiple systems and spreadsheets. Our integration solution simplifies the entire process by connecting your ERP or POS system with popular marketplaces and e-commerce platforms. With just a few clicks, you can sync your product catalog, update inventory levels, and process orders in real-time, saving you time and resources.",
    },
    {
        icon: "fas fa-check",
        content: "Unlock new opportunities for growth and reach a wider audience with our integration solution. Showcase your products on leading marketplaces like Amazon, Flipkart, Lazada, Shopee, Qoo10, and more, and watch your sales soar. Whether you're selling fashion, electronics, or home goods, our platform enables you to expand your reach and maximize your online presence.",
    },
    {
        icon: "fas fa-check",
        content: "Boost productivity and streamline your operations with our advanced features. Our integration solution ensures accurate inventory management, preventing stockouts and overselling. Plus, with automated order processing, you can fulfill orders faster and keep your customers happy. Spend less time on manual tasks and more time growing your business.",
    },
]

const Sellersspec = (props) => {
    // const swiperRef = React.useRef(null)
   
      
    return (
        <div style={props.style}>
            {/* Header Start */}
            <Header topSpace={{ desktop: true }} type="reverse-scroll">
                <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-0" containerClass="sm:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="alt-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="mobile-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block">
                        <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                        <SearchBar className="pr-0" />
                        <HeaderLanguage />
                        <HeaderCart />
                    </Col>
                </HeaderNav>
            </Header>
            {/* Header End */}
            <SideButtons />

            {/* Parallax Section Start */}
            <div className="py-[80px] h-auto overflow-hidden md:relative md:py-[40px]">
                <Parallax className="lg-no-parallax absolute top-[0] w-full h-full lg:bg-cover" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/webp/2149074076-min.webp)` }}></Parallax>
                <Container className="h-full relative">
                    <Row className="justify-center h-[300px] sm:h-[250px]">
                        <Col xl={12} lg={12} sm={8} className="text-center flex justify-center flex-col font-serif">
                            <h1 className="text-gradient bg-[#ffffff] mb-[15px] inline-block text-xmd leading-[20px]">Synchronize Products, Inventory, and Orders Across Multiple Platforms Effortlessly</h1>
                            <h4 className="text-white font-medium -tracking-[1px] mb-0">Sellers Spec provides the means to revolutionize your ecommerce operations with seamless integration.</h4>
                            
                        </Col>
                        </Row>
                    

                </Container>
            </div>
            {/* Parallax Section End */}

           

            {/* About section Start */}
            <section className="py-[80px] overflow-hidden cover-background lg:py-[100px] md:py-[95px] sm:py-[80px] xs:py-[50px]" style={{ backgroundColor: "#ffffff" }}>
                 <section className="lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-lightgray">
        <Container>
          <Row>
            <Col>
              <h6 className="font-serif text-darkgray text-center font-medium mb-[25px] sm:mb-[15px]">
                INTEGRATIONS
              </h6>
            </Col>
          </Row>
          <Clients
            grid="row-cols-1 row-cols-lg-5 row-cols-md-3 row-cols-sm-2"
            theme="client-logo-style-04"
            data={ClientData04}
            animation={fadeIn}
          />
        </Container>
      </section>
                <Container>
                    <Row className="items-center justify-center">
                        <m.div className="col-lg-6 py-[80px] col-md-10 md:mb-20 consulting-about" {...fadeIn}>
                            <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">
Elevating E-Commerce Standards: Empowering Quality through Sellers Spec by <span className="text-[#063f74] font-semibold">HasoTechnology</span></h2>
                            <p className="w-[70%] mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]">Welcome to the future of e-commerce management! Say goodbye to manual processes and hello to effortless integration with our cutting-edge solution. Our platform empowers businesses like yours to synchronize products, inventory, and orders across multiple marketplaces and e-commerce platforms seamlessly. Whether you're a small boutique or a large enterprise, our solution is designed to streamline your operations and drive growth.</p>
                            <Lists theme="list-style-02" data={listData} />
                            <div className="mt-[50px] md:mt-[25px] xs:mt-[35px] landscape:md:mt-[40px]">
                            <Buttons  ariaLabel="Contact us" href="/contact-us" className="mr-[35px] btn-fill btn-fancy rounded-none font-medium font-serif uppercase md:mb-[15px] xs:mb-0 " themeColor="#063f74" color="#fff" size="sm" title="Contact us" />
                            
                    {/* <Buttons ariaLabel="Contact us" href="/contact-us" className="mx-[10px] font-medium text-black font-serif uppercase hover:text-white btn-expand" size="xl" color="#fff" themeColor="#003cb3" to="/elements/accordions" title="Contact us" /> */}
                    
                                {/* <Buttons to="/our-services" className="font-medium pt-0 font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-blue xs:mb-0" size="xl" color="#003cb3" title="Discover Other Services" /> */}
                            </div>
                        </m.div>
                        <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
                            <div className="relative">
                                <div className="absolute h-full w-full top-0 left-0 opacity-20"></div>
                                <img className="w-full" alt="" src="/assets/img/logo_sellersSpec.png" width="500px" height="607.66px" />

                                {/* Modal Component Start */}
                                {/* <CustomModal.Wrapper
                                    className="absolute top-0 items-center flex h-full left-[-50px] md:right-0 md:justify-center md:w-full md:left-0"
                                    modalBtn={<Buttons ariaLabel="modal button" type="submit" className="btn-sonar border-0 shadow-[0_0_15px_rgba(0,0,0,0.1)]" themeColor="#fff" color="#bf8c4c" size="xxl" title={<i className="feather-play text-[35px]" />} />} >
                                    <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                        <div className="fit-video">
                                            <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                                        </div>
                                    </div>
                                </CustomModal.Wrapper> */}
                                {/* Modal Component End */}
                            </div>
                        </m.div>
                    </Row>
                </Container>
            </section>
            {/* About section End */}

            
            {/* Section Start */}
            {/* <m.div className="relative overflow-hidden py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] sm:px-[15px]" {...fadeIn}>
        <Parallax className="lg-no-parallax bg-cover cover-background absolute top-[-30px] left-0 h-[100vh] lg:h-[64vh] lg:top-[-75px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-0 sm:h-[56vh] xs:h-[50vh] w-full" translateY={[-40, 40]} style={{ backgroundImage: `url(https://via.placeholder.com/1920x1100)` }}></Parallax>
        <div className="left-0 top-0 h-full w-full absolute bg-darkslateblue opacity-75"></div>
        <Container className="relative">
          <Row className="justify-center items-center">
            <Col xl={7} md={8} sm={10} className="md:mb-[30px] sm:mb-[30px]">
              <h6 className="font-serif font-semibold text-white mb-0 sm:text-center">Ready to take your e-commerce operations to the next level?</h6>
              <h6 className="font-serif font-semibold text-white mb-0 sm:text-center">Request a demo today and see our integration solution in action.</h6>
              <h6 className="font-serif font-semibold text-white mb-0 sm:text-center">Discover how effortless integration can transform your business and drive success.</h6>
              <h6 className="font-serif font-semibold text-white mb-0 sm:text-center">Don't miss out on this opportunity to revolutionize your e-commerce operations.</h6>
            </Col>
            <Col xl={5} md={4} className="md:flex md:justify-center text-end">
              <Buttons ariaLabel="button" to="/" className="font-medium font-serif uppercase rounded-none lg:mb-[15px] landscape:lg:mb-[15px]" themeColor={["#2ed47c", "#e8a626"]} size="lg" color="#fff" title="Get started now!" />
            </Col>
          </Row>
        </Container>
      </m.div> */}
            {/* Section End */}

            {/* Section Start */}
      <section className="bg-lightgray py-[80px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <Row>
            <Col>
              <h6 className="font-serif text-dark text-center font-medium mb-[25px] lg:mb-[15px]">Features</h6>
            </Col>
          </Row>
          <IconWithText
            grid="row-cols-1 row-cols-lg-3 row-cols-sm-2"
            theme="icon-with-text-10"
            data={IconWithTextData_10}
            animation={fadeIn}
            animationDelay={0.2}
          />
        </Container>
      </section>
      {/* Section End */}

            {/* Section Start */}
            {/* <section className="bg-lightgray py-[100px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
                <Container>
                    <Row className="justify-center flex">
                        <m.div className="text-center mb-20 md:mb-12 col-lg-6 col-sm-8" {...fadeIn}>
                            <span class="text-xmd mb-[15px] font-serif block w-full">Unleash your e-commerce potential with our Sellers Spec development services at HasoTechnology. We prioritize development to ensure optimal performance and user satisfaction.</span>
                            <h5 class="font-serif text-darkgray font-medium mb-8 sm:w-full">Ensure Your Sellers Spec Excellence with Comprehensive Development</h5>

                        </m.div>
                        <Col xs={12} md={9} lg={12}>
                            <IconWithText
                                grid="row-cols-1 row-cols-md-1 row-cols-lg-2 gap-y-[15px]"
                                theme="icon-with-text-02 about-us-icon-with-text"
                                data={IconWithTextData}
                                animation={fadeIn}
                                animationDelay={0.1}
                            />
                        </Col>
                    </Row>
                </Container>
            </section > */}
            {/* Section End */}


                              {/* Section Start */}
      <m.section className="py-[130px] lg:py-[90px] md:py-[75px] xs:py-[50px]" {...fadeIn}>
        <Container>
          <Row className="items-center">
            <Col lg={6} className="p-0 md:mb-[50px] border-white border-[12px] rounded-lg shadow-[0_0_15px_rgba(0,0,0,0.1)]">
              <div className="relative">
              <Swiper
  className="white-move swiper-pagination-medium h-full swiper-navigation-01 swiper-navigation-light"
  modules={[Navigation, Autoplay, Keyboard]}
  spaceBetween={30}
  slidesPerView={1}
  loop={true}
  autoplay={{
    delay: 3000,
    disableOnInteraction: false,
  }}
  navigation={true}
  keyboard={{ enabled: true, onlyInViewport: true }}
>
  {TestimonialsCarouselData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="h-full shadow-lg bg-[#fff]">
        <img src={item.img} alt="business" className="w-full" width="531" height="613" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>

              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }}>
              <h5 className="font-serif text-darkgray font-medium">Ready to take your e-commerce operations to the next level?</h5>
              {/* <p>At HasoTechnology, we believe in the power of teamwork to bring your digital dreams to life. With a wealth of experience in web design and development, we take pride in crafting unique, creative, and high-quality solutions built on the latest coding standards.</p> */}
              <Lists theme="list-style-02" data={ListData1} className="mb-12 mt-8 text-darkgray font-serif" animation={fadeIn} />
              <Buttons href="/contact-us" className="btn-fill font-medium font-serif rounded-[4px] uppercase md:mb-[15px]" themeColor="#063f74" color="#fff" size="md" title="Get started now!" />
            </Col>

          </Row>
        </Container>
      </m.section>
      {/* Section End */}

            {/* Section Start */}
            <m.section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:pt-[50px] xs:pb-0" {...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col md={12} className="text-center mb-[6%]">
                            <h6 className="font-serif text-darkgray font-medium">Technologies We Use For Sellers Spec Development</h6>
                        </Col>
                    </Row>
                    <Services grid="row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-24 md:gap-y-[4rem] justify-center" theme='service-style-04' className="" data={serviceData34} animation={fadeIn} />
                </Container>
            </m.section>
            {/* Section End */}

            {/* Section Start */}
           {/*  <section className="px-[25px] bg-[#f7f7f7] py-[130px] overflow-hidden lg:py-[90px] sm:py-[70px] sm:pb-0 xs:py-[50px] xs:px-0">
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={4} sm={8} className="text-center text-lg-start md:mb-[50px]">
                            <h6 className="font-serif w-[80%] text-darkgray font-medium mb-[20px] lg:w-[90%] md:w-full md:mb-[10px]">Process We Make For Angular Application</h6>
                            <Buttons ariaLabel="button" to="/our-services/" className="text- font-medium font-serif uppercase btn-link after:h-[2px] md:text-md after:bg-royalblue hover:opacity-70" size="md" color="#0038e3" title="Discover Services" />
                        </Col>
                        <TextBox
                            grid="row-cols-1 row-cols-md-2 col-lg-8"
                            theme="text-box-style-02 flex-wrap lg:px-0"
                            className="sm:mb-[30px] xs:last:mb-0"
                            data={TextBoxData02.slice(0, 6)}
                            animation={fadeIn} />
                    </Row>
                </Container>
            </section>*/}


            {/* Section Start */}
            <section className="bg-white py-[80px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container fluid className="px-[12%] xl:px-[10%] lg:px-[3%]">
                    <Row>
                        <Col className="col-12 text-center mb-28">
                            <span className="block font-serif mb-[5px]">Most common questions</span>
                            <h5 className="font-serif text-darkgray font-semibold mb-0">Frequently asked questions</h5>
                        </Col>
                    </Row>
                    <Row className="gap-y-[30px]">
                        {listDataFaq.map((item, i) => {
                            return (
                                <m.div className="col-12 col-lg-4 col-sm-6" key={i} {...fadeIn}>
                                    <div className="faqs-page-list bg-white rounded box-shadow-small p-16 lg:p-12 h-full">
                                        <span className="font-serif text-xmd text-darkgray font-medium block mb-[15px]">{item.title}</span>
                                        <ul className="p-0 list-style text-start mb-[15px]">
                                            {item.content && item.content.map((item, i) => {
                                                return (
                                                    <li key={i}><Link to="/faq" className="hover:text-royalblue">{item}</Link></li>
                                                )
                                            })}
                                        </ul>
                                        <Buttons ariaLabel="Agency info" href="/faq" className="font-medium font-serif uppercase btn-link !tracking-[.5px] after:h-[2px] after:bg-hasocolor hover:text-hasocolor" size="md" color="#063f74" title="See all articles" />
                                    </div>
                                </m.div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            
            {/* Section End */}


            {/* Footer Start */}
            <FooterStyle03 theme="dark" className="text-[#828282] bg-[#0b0b26] consulting-footer" />
            {/* Footer End */}

        </div >
    )
}

export default Sellersspec